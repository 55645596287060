<template>
  <v-btn
    v-if="job"
    @click="favorite()"
    icon
  >
    <v-icon
      :color="$store.getters['jobs/isFavorite'](job.id) ? 'red' : 'grey'"
    >mdi-heart</v-icon>
  </v-btn>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    job: Object
  },

  data() {
    return {
      favorited: false,
    }
  },

  methods: {
    ...mapActions('jobs', [
        'toggleFavorite',
    ]),

    favorite(){
      this.toggleFavorite(this.job.id)
    },

  }

}
</script>